/**
 * Type: ページ
 * What: 17LIVE HKT48劇場ページ
 */
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Btn, Button } from '../components/Btn';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import Image from '../util/Image';

const pageTitle = '17LIVE HKT48劇場';
const pageDescription =
  '地元・福岡を拠点としながら、メディア出演をはじめ、コンサートやイベントなど全国で幅広く活動を行っている、HKT48。 グループの本拠地となる待望の専用劇場がBOSS E･ZO FUKUOKA 内に新たにオープンします。（HKT48の公演休館日は他のイベント等に貸出することも予定しています）';
const pageSlug = 'hkt';
const pageLogo = `logo-${pageSlug}-w.png`;

const sliderDataobj = [
  {
    filename: 'main-hkt.jpg',
  },
];

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="pageMain bs-1 bg-1">
      <div className="kv-wrap">
        <div className="kv-inner">
          <SimpleSwiper data={sliderDataobj} />
        </div>
        <div className="container">
          <div className="inner">
            <div className="pageMain-header">
              <div className="floorNo c-1">
                <span>1</span>
                <span>F</span>
              </div>
              <h2 className="ttl">
                <Image src={pageLogo} alt={pageTitle} />
              </h2>
            </div>

            <div className="overview">
              <p className="text">
                福岡を拠点に幅広く活動するHKT48の専用劇場が誕生
                <br className="sp" />
              </p>
              <p className="text">
                地元・福岡を拠点としながら、メディア出演をはじめ、コンサートやイベントなど全国で幅広く活動を行っている、HKT48。グループの本拠地となる待望の専用劇場がBOSS
                E･ZO FUKUOKA 内に新たにオープンします。
              </p>
              <p className="list-mark c-12">
                （HKT48の公演休館日は他のイベント等に貸出することも予定しています）
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section bg-14">
      <div className="container">
        <div className="inner">
          <div className="columns">
            <div className="column">
              <figure className="image">
                <Image filename="hkt-group-photo.jpg" />
              </figure>
            </div>
          </div>
          <div
            className="btn-wrap"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <Button
              href="http://www.hkt48.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              公式サイトはこちらから
            </Button>
          </div>

          {/*
          <div className="content-box bg-16">
            <h2 className="title is-3 en2 c-17 has-text-align-center">Information</h2>
            <table className="table is-narrow is-fullwidth">
              <tbody>
                <tr>
                  <th>営業時間</th>
                  <td className="tal">
                    平日：11:00~20:00
                    <br />
                    土曜日：9:00~22:00
                    <br />
                    日曜日：9:00~20:00
                    <br />
                    <p className="list-mark">
                      <span className="attention">※</span>
                      みずほPayPayドームナイター開催日、イベント開催日は変更の可能性有
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>チケット料金</th>
                  <td className="tal">
                    16歳以上：2200円
                    <br />
                    15歳以下：800円
                  </td>
                </tr>
                <tr>
                  <th>購入方法</th>
                  <td className="tal">WEBまたはE・ZO FUKUOKA 3階券売機</td>
                </tr>
              </tbody>
            </table>
        </div>
          */}
        </div>
      </div>
    </section>
  </Layout>
);

export default Template;
